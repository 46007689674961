import React from 'react'

const Cart = () => {
  return (
    <>
      <div className="max-w-[1540px] mt-[60px] lg:mt-[80px] p-4">

        <h1>This is Cart</h1>

      </div>
    </>
  )
}

export default Cart